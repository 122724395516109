import React, {Fragment, useState} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import {useForm} from 'react-hook-form'

export default function EmailDocumentForm(props) {
    const EMAIL_STATUS = {None: 0, Sending: 1, Sent: 2, Error: 3}
    const translations = {
        sv: {
            emailPlaceholder: 'Din epostadress',
            emailError: 'Giltig epostadress och val av dokument krävs',
            sendButton: 'Skicka dokument',
            emailSending: 'Skickar...',
            emailSendingError: 'Någonting gick fel! Vänligen skicka email till info@cppgarments.com.',
            emailSent: 'Vi har skickat dig de efterfrågade dokumenten, kolla din epost!',
        },
        en: {
            emailPlaceholder: 'Your email',
            emailError: 'A valid email address and choice of documents is required',
            sendButton: 'Email the documents',
            emailSending: 'Sending...',
            emailSendingError: 'Something went wrong! Please email info@cppgarments.com.',
            emailSent: 'We\'ve sent you the requested documents, please check your email',
        },
    }

    const {register, handleSubmit, errors} = useForm()
    const [emailStatus, setEmailStatus] = useState(EMAIL_STATUS.None)
    const t = translations[props.lang]

    const onSubmit = (data) => {
        data.lang = props.lang
        setEmailStatus(EMAIL_STATUS.Sending)
        axios
            .post('/api/documents_emails', data)
            .then((response) => {
                setEmailStatus(EMAIL_STATUS.Sent)
            })
            .catch((error) => {
                setEmailStatus(EMAIL_STATUS.Error)
            })
    }

    const renderDocument = (doc, ix) => (
        <div className="form-check custom-control custom-checkbox" key={`documentWrapper${ix}`}>
            <input
                className="form-check-input custom-control-input"
                type="checkbox"
                id={`fileCheckbox${ix}`}
                ref={register({
                    validate: v => v.length > 0
                })}
                name="doc"
                value={doc.fileId}
            />
            <label className="form-check-label custom-control-label" htmlFor={`fileCheckbox${ix}`}>
                {doc.title}
            </label>
        </div>
    )

    const emailForm = () => (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className="form-group">
                <input
                    type="text"
                    ref={register({required: true})}
                    name="email"
                    placeholder={t.emailPlaceholder}
                    className="form-control"
                />
            </div>
            <div className="form-group">{props.documents.map(renderDocument)}</div>
            {(errors.email || errors.doc) && <p className="text-danger">{t.emailError}</p>}
            <div className="form-group">
                <input type="submit" value={t.sendButton} className="btn btn-primary"/>
            </div>
        </form>
    )

    return (
        <Fragment>
            {emailStatus === EMAIL_STATUS.None ? emailForm() : null}
            {emailStatus === EMAIL_STATUS.Sending ? (
                <p className="text-center documents-sending">
                    <i className="icon-spinner animate-spin"/>
                    {t.emailSending}
                </p>
            ) : null}
            {emailStatus === EMAIL_STATUS.Sent ? (
                <p className="text-center documents-sent">{t.emailSent}</p>
            ) : null}
            {emailStatus === EMAIL_STATUS.Error ? (
                <p className="text-center text-danger documents-sent">{t.emailSendingError}</p>
            ) : null}
        </Fragment>
    )
}

document.addEventListener('DOMContentLoaded', () => {
    const documents = Array.prototype.map.call(
        document.getElementsByClassName('email-document'),
        (doc) => ({
            title: doc.getAttribute('data-title'),
            fileId: doc.getAttribute('data-file-id'),
        })
    )

    const el = document.getElementById('sendDocumentsFormContainer')
    if (el)
        ReactDOM.render(
            <EmailDocumentForm lang={el.dataset.lang} documents={documents}/>,
            el
        )
})

